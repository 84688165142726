<template>
  <v-app>
    <v-main class="auth-pages">
      <!-- your content here -->
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>

export default {
  name: "auth-layout",
  data() {
    return {
      paragraphs: "",
    };
  }
};
</script>
