import Vue from "vue";
import App from "./App.vue";
import { initRouter } from "./router";
import vuetify from "./plugins/vuetify";
import DashboardPlugin from "./plugins/dashboard-plugin";
import { setupFirebase } from "./data";

Vue.config.productionTip = false;

// plugin setup
Vue.use(DashboardPlugin);

window.initVue = function() {
  window.vueApp = new Vue({
    data: window.appData,
    router: initRouter(),
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
};

setupFirebase();
