<template>
  <v-navigation-drawer
    width="250"
    fixed
    app
    floating
    :expand-on-hover="mini"
    :value="drawer"
  >
    <v-list-item class="pa-0">
      <v-list-item-content class="pa-0">
        <v-list-item-title class="title d-flex align-center mb-0">
          <div class="v-navigation-drawer-brand pa-5 d-flex align-center">
            <img src="@/assets/logo.svg" class="w-70" />
          </div>

          <div
            class="drawer-toggler pa-5 cursor-pointer ml-auto"
            :class="{ active: togglerActive }"
            @click="minifyDrawer"
          >
            <div class="drawer-toggler-inner">
              <i class="drawer-toggler-line bg-default"></i>
              <i class="drawer-toggler-line bg-default"></i>
              <i class="drawer-toggler-line bg-default"></i>
            </div>
          </div>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <div class="pa-2" v-if="$root.isAdmin">
      <v-select
        @input="onInput"
        class="input-style font-size-input text-light-input placeholder-light"
        dense
        flat
        filled
        solo
        height="43"
        hide-details="true"
        :items="accounts"
        v-bind="defaultSelected"
        return-object
      ></v-select>
    </div>
    <v-list nav dense>
      <v-list-item-group v-if="showAdminPages">
        <v-list-item
          v-for="(item, i) in itemsAdmin"
          :key="i"
          link
          :to="{path: item.link, params: $route.params}"
          class="pb-1 no-default-hover"
          :ripple="false"
          active-class="item-active"
        >
          <v-list-item-icon>
            <v-icon v-text="item.action"></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <a href="https://portal.cashraven.io/en/support/tickets/new" class="pb-1 no-default-hover v-list-item v-list-item--link theme--light" tabindex="0"><div class="v-list-item__icon"><i aria-hidden="true" class="v-icon notranslate ni ni ni ni-chat-round v-icon-drawer text-cashraven-blue theme--light"></i></div><div class="v-list-item__content"><div class="v-list-item__title">Contact</div></div></a>
        <a href="https://cashraven.io/faq/#" class="pb-1 no-default-hover v-list-item v-list-item--link theme--light" tabindex="0"><div class="v-list-item__icon"><i aria-hidden="true" class="v-icon notranslate ni ni ni ni-notification-70 v-icon-drawer text-cashraven-blue theme--light"></i></div><div class="v-list-item__content"><div class="v-list-item__title">FAQ</div></div></a>
      </v-list-item-group>
      <v-list-item-group v-else>
        <v-list-item
          v-for="(item, i) in itemsUser"
          :key="i"
          link
          :to="{name: item.name, query: $route.query}"
          class="pb-1 no-default-hover"
          :ripple="false"
          active-class="item-active"
        >
          <v-list-item-icon>
            <v-icon v-text="item.action"></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <a href="https://portal.cashraven.io/en/support/tickets/new" class="pb-1 no-default-hover v-list-item v-list-item--link theme--light" tabindex="0"><div class="v-list-item__icon"><i aria-hidden="true" class="v-icon notranslate ni ni ni ni-chat-round v-icon-drawer text-cashraven-blue theme--light"></i></div><div class="v-list-item__content"><div class="v-list-item__title">Contact</div></div></a>
        <a href="https://cashraven.io/faq/#" class="pb-1 no-default-hover v-list-item v-list-item--link theme--light" tabindex="0"><div class="v-list-item__icon"><i aria-hidden="true" class="v-icon notranslate ni ni ni ni-notification-70 v-icon-drawer text-cashraven-blue theme--light"></i></div><div class="v-list-item__content"><div class="v-list-item__title">FAQ</div></div></a>
      </v-list-item-group>
      <div class="border-bottom ma-3"></div>
    </v-list>
  </v-navigation-drawer>
</template>
<script>

import router from '@/router';

export default {
  name: "drawer",
  props: {
    drawer: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({
    mini: false,
    togglerActive: false,
    itemsAdmin: [
      {
        action: "ni ni-single-02 v-icon-drawer text-cashraven-blue",
        name: "Users",
        title: "Users",
      },
      // 
      // {
      //   action: "ni ni-chat-round v-icon-drawer text-cashraven-blue",
      //   link: "https://portal.cashraven.io/en/support/tickets/new",
      //   title: "Contact",
      // },
      // {
      //   action: "ni ni-notification-70 v-icon-drawer text-cashraven-blue",
      //   link: "https://cashraven.io/faq/",
      //   title: "FAQ",
      // },
    ],

    accounts: [
      {
        text: 'Admin',
        value: 'Admin',

      }, {
        text: 'User',
        value: 'User',
      }
    ],
  }),
  computed: {
    defaultSelected: function() {
      if (this.$route.meta.isAdminPage) {
        return {
          text: 'Admin',
          value: 'Admin',
        };
      } else {
        return {
          text: 'User',
          value: 'User',
        };
      }

      return {};
    },
    showAdminPages: function() {
      return (!!this.$route.meta.isAdminPage) && (!this.$route.meta.isAdminAsUserPage);
    },
    itemsUser: function () {
      let result = [];
      if (this.$route.meta.isAdminAsUserPage) {
        result = [
          {
            action: "ni ni-chart-bar-32 v-icon-drawer text-cashraven-blue",
            name: "AdminAsUserDashboard",
            title: "Dashboard",
          },
          {
            action: "ni ni-credit-card v-icon-drawer text-cashraven-blue",
            name: "AdminAsUserTransactions",
            title: "Transactions",
          },
          // These links are hardcode added to the list. If you add them through the itemsUser / itemsAdmin array, they will not be absolute, but relative. A bug needs to be fixed in the future.
          // {
          //   action: "ni ni-chat-round v-icon-drawer text-cashraven-blue",
          //   link: "https://portal.cashraven.io/en/support/tickets/new/",
          //   title: "Contact",
          // },
          // {
          //   action: "ni ni-notification-70 v-icon-drawer text-cashraven-blue",
          //   link: "https://cashraven.io/faq/",
          //   title: "FAQ",
          // },
        ];
     } else {
        result = [
          {
            action: "ni ni-chart-bar-32 v-icon-drawer text-cashraven-blue",
            name: "Dashboard",
            title: "Dashboard",
          },
          {
            action: "ni ni-credit-card v-icon-drawer text-cashraven-blue",
            name: "Transactions",
            title: "Transactions",
          },
          // These links are hardcode added to the list. If you add them through the itemsUser / itemsAdmin array, they will not be absolute, but relative. A bug needs to be fixed in the future.
          // {
          //   action: "ni ni-chat-round v-icon-drawer text-cashraven-blue",
          //   link: "https://portal.cashraven.io/en/support/tickets/new/",
          //   title: "Contact",
          // },
          // {
          //   action: "ni ni-notification-70 v-icon-drawer text-cashraven-blue",
          //   link: "https://cashraven.io/faq/",
          //   title: "FAQ",
          // },
        ];
     }
     return result;
    }

  },
  methods: {
    minifyDrawer() {
      this.togglerActive = !this.togglerActive;
      this.mini = !this.mini;
      const body = document.getElementsByTagName("body")[0];

      if (body.classList.contains("drawer-mini")) {
        body.classList.remove("drawer-mini");
      } else {
        body.classList.add("drawer-mini");
      }
    },
    onInput(newValue) {
      if(newValue.value === 'Admin') {
        this.$router.push({ name: 'Users' });
      } else {
        this.$router.push({ name: 'Dashboard' });
      }
    },
  },
};
</script>
