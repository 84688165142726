'use strict';
let apiBaseUrl = "https://admin-backend.cashraven.io";
//let apiBaseUrl = "https://admin-backend.cashraven.io:444";
//if (document.location.hostname == "localhost") {
//  apiBaseUrl = "http://localhost:9081";
//};

export const getAdminUserList = async function() {
  try {
    let idToken = await window.appData.user.getIdToken();
    let url = encodeURIComponent;
    let response = await fetch(apiBaseUrl + `/api/v1/userlist`, {
      headers: {
        "Authorization": "Bearer " + idToken,
      }
    });
    let responseJSON = await response.json();
    console.log(responseJSON)
    return responseJSON.users;
  } catch (e) {
    console.error(e);
  }
};
window.getAdminUserList = getAdminUserList;

export const getTrafficChartData = async function(from, to, userId) {
  try {
    let idToken = await window.appData.user.getIdToken();
    let url = encodeURIComponent;
    let response = await fetch(apiBaseUrl +
      `/api/v1/traffic-data?from=${url(from.toISOString())}&to=${url(to.toISOString())}&timezone=${url(Intl.DateTimeFormat().resolvedOptions().timeZone)}&uid=${userId}`, {
      headers: {
        "Authorization": "Bearer " + idToken,
      }
    });
    let responseJSON = await response.json();
    //console.log(responseJSON)
    let buckets = responseJSON.aggregations.total_traffic_over_time.buckets;
    let result = [];
    for (let bucket of buckets) {
      result.push({
        x: new Date(bucket.key),
        y: bucket.total_traffic.value,
      });
    }
    return result;
  } catch (e) {
    console.error(e);
  }
};

export const getTrafficChartDataForLastMonth = async function(userId) {
  let from = new Date();
  from.setMonth(from.getMonth() - 1);
  from.setHours(0);
  from.setMinutes(0);
  from.setSeconds(0);
  from.setMilliseconds(0);

  let to = new Date();
  to.setDate(to.getDate() + 1);
  to.setHours(0);
  to.setMinutes(0);
  to.setSeconds(0);
  to.setMilliseconds(0);

  let response = await getTrafficChartData(from, to, userId);

  window.appData.trafficChartData = response;

  return response;
};
//window.getLastMonthData = getTrafficChartDataForLastMonth;

async function requestSummary(from, to) {
  let idToken = await window.appData.user.getIdToken();
  let response = await fetch(apiBaseUrl + `/api/v1/traffic-summary?from=${encodeURIComponent(from.toISOString())}&to=${encodeURIComponent(to.toISOString())}`, {
    headers: {
      "Authorization": "Bearer " + idToken,
    }
  });
  let responseJSON = await response.json();
  return responseJSON
};

async function requestIsAdmin() {
  let idToken = await window.appData.user.getIdToken();
  let response = await fetch(apiBaseUrl + `/api/v1/isadmin`, {
    headers: {
      "Authorization": "Bearer " + idToken,
    }
  });
  let responseJSON = await response.json();
  return responseJSON;
};



export const getEarningsData = async function() {
  try {
    let epoch = new Date(0);

    let from = new Date();
    //from.setDate(from.getDate());
    from.setHours(0);
    from.setMinutes(0);
    from.setSeconds(0);
    from.setMilliseconds(0);

    let to = new Date();
    to.setDate(to.getDate() + 1);
    to.setHours(0);
    to.setMinutes(0);
    to.setSeconds(0);
    to.setMilliseconds(0);

    let oneDayPromise = requestSummary(from, to);
    let allTimePromise = requestSummary(epoch, to);

    let oneDayData = await oneDayPromise;
    let allTimeData = await allTimePromise;

    let oneDayTraffic = oneDayData.aggregations.total_traffic.value;
    let allTimeTraffic = allTimeData.aggregations.total_traffic.value;

    console.log(oneDayTraffic);
    console.log(allTimeTraffic);

    window.appData.trafficToday = oneDayTraffic;
    window.appData.trafficTotal = allTimeTraffic;

    window.appData.earnedToday = (oneDayTraffic / 1024 / 1024 / 1024) * 0.1;
    window.appData.earnedTotal = (allTimeTraffic / 1024 / 1024 / 1024) * 0.1 + window.appData.initialBonus;

    window.appData.balance = window.appData.earnedTotal;
  } catch (e) {
    console.error(e);
  }
}
//window.getSummary = getEarningsData;


console.log("place 6")
export const getUserInfo = async function(uid) {
  let idToken = await window.appData.user.getIdToken();
  let response = await fetch(apiBaseUrl + `/api/v1/userinfo?uid=${uid}`, {
    headers: {
      "Authorization": "Bearer " + idToken,
    }
  });
  let responseJSON = await response.json();
  return responseJSON;
}
console.log("place 7")

export const createPayout = async function(email, userinfo) {
  await firebase.database().ref().child("payouts").child(userinfo.uid).push({
    email: email,
    timestamp: Date.now(),
    status: 'requested',
    kind: 'paypal',
    amount: userinfo.balance,
    traffic: userinfo.trafficBalance,
    comment: '',
  });
}

export const updatePayout = async function(userId, payoutId, updates) {
  await firebase.database().ref().child("payouts").child(userId).child(payoutId).update(updates);
}

export const googleLogin = function() {
  var provider = new firebase.auth.GoogleAuthProvider();
  provider.addScope('https://www.googleapis.com/auth/userinfo.email');
  firebase.auth()
    .signInWithPopup(provider)
    .then(async (result) => {
      console.log('Signed in', result)
      /** @type {firebase.auth.OAuthCredential} */
      var credential = result.credential;

      // This gives you a Google Access Token. You can use it to access the Google API.
      var token = credential.accessToken;
      // The signed-in user info.
      var user = result.user;
      window.appData.user = user;
      window.appData.isAdmin = await requestIsAdmin();
      //See router.beforeEach(). It should handle default cases for admin and non-admin users
      window.vueApp.$router.push("/").catch(()=> {});//Catch is needed because redirect in beforeEach will treated as failed attempt to navigate to '/'
      // ...
    }).catch((error) => {
      console.error(error);
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      // ...
    });
}

export const logout = function() {
  firebase.auth().signOut().then(() => {
    window.appData.user = null;
    window.appData.isAdmin = false;
    console.log('Signed out');
    //See router.beforeEach(). It should handle unauthorized case
    window.vueApp.$router.push("/").catch(() => {});//Catch is needed because redirect in beforeEach will treated as failed attempt to navigate to '/'
  }).catch((error) => {
    console.error(error);
  });
}

export const setupFirebase = function() {

  const ALPHABET = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
  const STATUSES = ['requested', 'cancelled', 'paid'];
  const DESCRIPTION = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras aliquet varius magna, non porta ligula feugiat eget. Fusce tristique felis at fermentum pharetra. Aliquam id orci ut lectus varius viverra. Nullam nunc ex, convallis sed finibus eget, sollicitudin eget ante. Phasellus eros mauris, condimentum sed nibh vitae, sodales efficitur ipsum. Sed blandit, eros vel aliquam faucibus, purus ex euismod diam, eu luctus nunc ante ut dui. Sed sed nisi sed augue convallis suscipit in sed felis. Aliquam erat volutpat. Nunc fermentum tortor ac porta dapibus. In rutrum ac purus sit amet tempus.';

  const getRandomInteger = (a = 0, b = 1) => {
    const lower = Math.ceil(Math.min(a, b));
    const upper = Math.floor(Math.max(a, b));
    return Math.floor(lower + Math.random() * (upper - lower + 1));
  };

  const getRandomString = (text, split) => {
    const textArray = text.split(split);
    const lengthString = getRandomInteger(1, textArray.length - 1);
    let newString = '';

    for(let i = 0; i < lengthString; i++) {
      const string = textArray[getRandomInteger(0, textArray.length - 1)];
      newString += (i === lengthString - 1) ? string : `${string} `;
      textArray.splice(textArray.indexOf(string), textArray.indexOf(string));
    }
    return newString;
  };

  window.appData = {
    user: null,
    isAdmin: false,

    currentUserInfo: null,

    adminData: {
      adminIntoUser: false,
      currentUser: {
        id: '',
        name: '',
        email: '',
        amount: '',
        status: '',
      },
      users: [
        {
          id: '',
          name: '',
          email: '',
          amount: '',
          status: '',
        }
      ],
    },
    currentDashboardData: {
      uid: '',
      email: '',
      trafficChartData: [],
      trafficToday: 0,
      trafficBalance: 0,
      trafficTotal: 0,
      earnedToday: 0,
      earnedTotal: 0,
      balance: 0,
      initialBonus: 5,
      devices: [
        {
          name: '',
          id: '',
          balance: 0,
          traffic: 0,
          platform: '',  // android/windows
        }
      ],
      transactions: [
        {
          id: '',
          date: '',
          type: 'paypal',
          email: '',
          amount: '',
          status: 'requested',
          comment: '',
        }
      ],
      lastTransaction: {
        email: '',
        amount: '',
      },
    },
  }



  /*
  var apiBaseUrl = 'https://admin-backend.cashraven.io';
  if (document.location.hostname == 'localhost') {
    apiBaseUrl = 'http://localhost:9081';
  }

  async function getTrafficData(idToken, trafficDataSpan) {
    try {
      let prevMonthDate = new Date();
      prevMonthDate.setUTCMonth(prevMonthDate.getUTCMonth() - 1);
      let now = new Date();
      let response = await fetch(apiBaseUrl + `/api/v1/traffic-data?from=${encodeURIComponent(prevMonthDate.toISOString())}&to=${encodeURIComponent(now.toISOString())}`, {
        headers: {
          'Authorization': 'Bearer ' + idToken,
        }
      });
      let responseJSON = await response.json();
      trafficDataSpan.innerHTML = `<pre>${JSON.stringify(responseJSON, null, 2)}</pre>`;
    } catch (e) {
      console.error(e);
    }
  }
  */

  var firstOnAuthStateChanged = true;

  console.log('place 1');
  document.addEventListener('DOMContentLoaded', function() {
    console.log('place 2');
    firebase.auth().onAuthStateChanged(async (user) => {
      console.log('place 3');
      window.appData.user = user;
      window.appData.isAdmin = false;

      if(user != null) {
        let isAdmin = await requestIsAdmin();
        window.appData.isAdmin = isAdmin;
      }

      if(firstOnAuthStateChanged) {
        window.initVue();
        firstOnAuthStateChanged = false;
      }
    });
    console.log('place 4');
  });
  console.log('place 5');

  // Если пользователь не залогинился/разлогинился --> login enabled, logout disabled
}
