<template>
  <v-app-bar
    :color="background"
    dark
    height="auto"
    class="py-4 px-2 toolbar-content-padding-y-none"
    :class="{ 'border-bottom': !adminAsDifferentUser, 'admin-user-header': false && adminAsDifferentUser }"
    flat
  >
    <v-row>
      <v-col cols="12" sm="12" class="text-right d-flex align-center justify-end flex-wrap">

        <v-btn
          elevation="0"
          :ripple="false"
          height="43"
          class="font-weight-600 text-capitalize drawer-toggler py-3 px-6 rounded-sm"
          :class="{
            'btn-dark-toggler-hover': !hasBg,
            'btn-toggler-hover': hasBg,
            active: togglerActive,
          }"
          v-if="$vuetify.breakpoint.mobile"
          color="transparent"
          @click="drawerClose"
        >
          <div class="drawer-toggler-inner">
            <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg || adminAsDifferentUser }"></i>
            <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg || adminAsDifferentUser }"></i>
            <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg || adminAsDifferentUser }"></i>
          </div>
        </v-btn>

        <div 
        v-if="adminAsDifferentUser"
        class="mr-auto d-flex align-center">
          <v-btn
            fab
            width="24"
            height="24"
            elevation="0"
            color="transparent"
            class="mr-6"
            @click="returnToAdminProfile"
          >
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="16" height="16" viewBox="0 0 24 24">
              <path fill="#FFFFFF" d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z" />
            </svg>
          </v-btn>
          <p color="#FFFFFF" class="mb-0">
            {{$root.currentUserInfo.email}}
          </p>

        </div>


        <div class="app-bar__android mr-8">
          <svg class="d-block mr-3" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
              <path d="M9 27C9 27.825 9.675 28.5 10.5 28.5H12V33.75C12 34.995 13.005 36 14.25 36C15.495 36 16.5 34.995 16.5 33.75V28.5H19.5V33.75C19.5 34.995 20.505 36 21.75 36C22.995 36 24 34.995 24 33.75V28.5H25.5C26.325 28.5 27 27.825 27 27V12H9V27ZM5.25 12C4.005 12 3 13.005 3 14.25V24.75C3 25.995 4.005 27 5.25 27C6.495 27 7.5 25.995 7.5 24.75V14.25C7.5 13.005 6.495 12 5.25 12ZM30.75 12C29.505 12 28.5 13.005 28.5 14.25V24.75C28.5 25.995 29.505 27 30.75 27C31.995 27 33 25.995 33 24.75V14.25C33 13.005 31.995 12 30.75 12ZM23.295 3.24L25.245 1.29C25.545 0.99 25.545 0.525 25.245 0.225C24.945 -0.075 24.48 -0.075 24.18 0.225L21.96 2.445C20.7325 1.82338 19.3759 1.49964 18 1.5C16.56 1.5 15.21 1.845 14.01 2.445L11.775 0.225C11.475 -0.075 11.01 -0.075 10.71 0.225C10.41 0.525 10.41 0.99 10.71 1.29L12.675 3.255C11.5358 4.08853 10.6093 5.17901 9.9708 6.43787C9.33225 7.69672 8.99964 9.08845 9 10.5H27C27 7.515 25.545 4.875 23.295 3.24ZM15 7.5H13.5V6H15V7.5ZM22.5 7.5H21V6H22.5V7.5Z" fill="#43A4FF"/>
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="36" height="36" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <a class="app-bar__android-link" href="https://cashraven.io/download/" target="_blank">Get Android app</a>
        </div>

        <v-menu transition="slide-y-transition" offset-y :position-x="50">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              :ripple="false"
              class="font-weight-600 ls-0 text-capitalize pe-0"
              color="transparent"
              v-bind="attrs"
              v-on="on"
            >
              <v-img
                width="36"
                v-bind:src="$root.user.photoURL ? $root.user.photoURL : defaultUserImage"
                class="me-2 rounded-circle"
              ></v-img>
              <span 
                v-if="!adminAsDifferentUser"
                :class="{ 'btn-hover': hasBg }"
                style="color: rgba(0,0,0, .6)"
              >
                {{$root.user.displayName}}
              </span>
              <span
                v-else
                :class="{ 'btn-hover': hasBg }"
                style="color: #FFFFFF"
              >
                {{$root.currentUserInfo.name}}
              </span>
            </v-btn>
          </template>

          <v-list min-width="192" max-width="192">
            <div @click="logout()" tabindex="0" role="menuitem" id="list-item-50" class="min-height-auto v-list-item v-list-item--link theme--light border-bottom">
              <div class="v-avatar v-list-item__avatar my-0 me-5" style="height: 37px; min-width: 15px; width: 15px;">
                <i aria-hidden="true" class="v-icon notranslate text-darker ni ni-user-run theme--light"></i>
              </div>
              <div class="v-list-item__content pa-0"><div class="v-list-item__title text-darker">Logout</div>
            </div>
            <div class="border-bottom"></div>
            </div>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<style lang="css" scoped>
  .admin-user-header {
    background-color: #6A6A6A !important;
  }
</style>

<script>

import defaultUserImage from '@/assets/img/team-4.jpg';
import { logout } from "@/data.js";

export default {
  name: "app-bar",
  props: {
    background: String,
    hasBg: Boolean,
    linkColor: String,
    // toggleActive: String,
  },
  data() {
    return {
      defaultUserImage: defaultUserImage,
      drawer: false,
      togglerActive: false,
    };
  },
  computed: {
    adminAsDifferentUser: function() {
      return this.$route.meta.isAdminAsUserPage;
    },
  },
  methods: {
    drawerClose() {
      this.togglerActive = !this.togglerActive;
      this.$emit("drawer-toggle", true);
    },
    returnToAdminProfile() {
      this.$router.push({name: 'Users'});
    },
    logout,
  },
  watch: {
    toggleActive(val) {
      this.togglerActive = val;
    },
  },
};
</script>
