import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import AuthLayout from "@/views/Pages/AuthLayout";

// Dashboard pages
const Dashboard = () => import("../views/Dashboard/Dashboard.vue");
const Transactions = () => import("../views/Dashboard/Transactions.vue");
const Users = () => import("../views/Dashboard/Users.vue");

// Pages
const Login = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Login.vue");

import { getUserInfo } from "@/data.js";

Vue.use(VueRouter);

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
    }
  ],
};

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    name: "home",
    //redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          groupName: "Dashboards",
          adminDrawerValue: 'user-mode',
          isUserPage: true,
        },
      },
      {
        path: "/transactions",
        name: "Transactions",
        component: Transactions,
        meta: {
          groupName: "Dashboards",
          adminDrawerValue: 'user-mode',
          isUserPage: true,
        },
      },

      {
        path: "/admin/users",
        name: "Users",
        component: Users,
        meta: {
          groupName: "Dashboards",
          adminDrawerValue: 'admin-mode',
          isAdminPage: true,
        },
      },
      {
        path: "/admin/user/:userId/dashboard",
        name: "AdminAsUserDashboard",
        component: Dashboard,
        meta: {
          groupName: "Dashboards",
          adminDrawerValue: 'user-mode',
          isUserPage: true,
          isAdminPage: true,
          isAdminAsUserPage: true,
        },
      },
      {
        path: "/admin/user/:userId/transactions",
        name: "AdminAsUserTransactions",
        component: Transactions,
        meta: {
          groupName: "Dashboards",
          adminDrawerValue: 'user-mode',
          isUserPage: true,
          isAdminPage: true,
          isAdminAsUserPage: true,
        },
      },

      {
        path: "https://portal.cashraven.io/en/support/tickets/new",
        name: "Contact",
      },
      {
        path: "https://cashraven.io/faq/#",
        name: "FAQ",
      },
    ],
  },
  authPages,
];

export const initRouter = function() {
  const router = new VueRouter({
    routes,
  });

  router.beforeEach((to, from, next) => {
    console.log(from.path, to.path);
    let isAuthorized = window.appData.user != null;
    let isAdmin = isAuthorized && window.appData.isAdmin;

    if (!isAuthorized) {
      if (to.name !== 'Login') {
        next({ name: 'Login' });
        return;
      }
    }

    if (isAuthorized) {
      if (to.path == '/') {
        if (isAdmin) {
          next({ name: 'Users' });
        } else {
          next({ name: 'Dashboard' });
        }
        return;
      } else if (to.name == 'Login') {
        next(false);
        return;
      }
    }

    if (to.meta.isAdminPage && !isAdmin) {
      next(false);
      return;
    }

    // User info data is being used not only in component loaded for current
    // Dashboard but appbar and left drawer also.
    //
    // This adds complexity on "Who's responsible for loading and updating
    // data"
    //
    // At this point I decided that it is best to load it from router then
    // trying to communicate data between components in some way.
    //
    //     - Oleksii Moskal
    if (isAuthorized) {
      window.appData.currentUserInfo = null;
      window.appData.adminIntoUser = false;
      window.appData.currentUserId = '';

      if (to.meta.isUserPage) {
        if (isAdmin) {
          //if (to.query.userId && to.query.userId != window.appData.user.uid) {
          //  window.appData.adminIntoUser = true;
          //}
        }

        if (to.meta.isAdminPage) {
          window.appData.currentUserId = to.params.userId;
        } else {
          window.appData.currentUserId = window.appData.user.uid;
        }
        window.appData.currentUserInfo = {
          uid: '',
          email: '',
          trafficChartData: [],
          trafficToday: 0,
          trafficBalance: 0,
          trafficTotal: 0,
          earnedToday: 0,
          earnedTotal: 0,
          balance: 0,
          initialBonus: 5,
          devices: [
            //{
            //  name: '',
            //  id: '',
            //  balance: 0,
            //  traffic: 0,
            //  platform: '',  // android/windows
            //}
          ],
          transactions: [
            //{
            //  id: '',
            //  date: '',
            //  type: 'paypal',
            //  email: '',
            //  amount: '',
            //  status: 'requested',
            //  comment: '',
            //}
          ],
          //lastTransaction: {
          //  email: '',
          //  amount: '',
          //},
        };

        (async function() {
          window.appData.currentUserInfo = await getUserInfo(appData.currentUserId);
        })();
      }
    }

    next();
  });

  return router;
}
